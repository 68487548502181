<table *ngIf="summary; else loadingSummary" class="table table-bordered">
    <th></th>
    <th><a [routerLink]="['/balances/materials/',budgetId]" i18n>Materials</a></th>
    <th i18n><a [routerLink]="['/balances/activities/',budgetId]">Activities</a></th>
    <th i18n>Total Cost</th>
    <th i18n>Commissions</th>
    <th i18n>Custom Duties</th>
    <th *ngIf="budget.export" i18n>Taxes</th>
    <th i18n>Fatturato</th>
    <th i18n>Gen Expenses & profits</th>
    <th i18n>%</th>
    <tr>
        <td>Budget</td>
        <td>{{ summary.costSummary.budget.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.budget.act | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.budget.totalCost  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.budget.commissions  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.budget.customDuties  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.export">{{ summary.costSummary.budget.taxes | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.finalOffer | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.profit.budget | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ +(summary.costSummary.profit.budget / summary.costSummary.finalOffer) | percent:'1.2' }}</td>
    </tr>
    <tr>
        <td>Consuntivo</td>
        <td>{{ summary.costSummary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.final.act  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.final.totalCost  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.final.commissions  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td>{{ summary.costSummary.final.customDuties  | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td *ngIf="budget.export">{{ summary.costSummary.final.taxes| currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <!--Fatturato-->
        <td>{{ summary.costSummary.revenue | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <!--Gen Expenses & profits-->
        <td [class.make-red]="!compare(summary.costSummary.profit.final,0)">
            {{ summary.costSummary.profit.final | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <!--%-->
        <td>{{ +(summary.costSummary.profit.final / summary.costSummary.revenue) | percent:'1.2' }}</td>
    </tr>

    <tr class="differences">
        <td>Difference</td>
        <td [class.make-red]="summary.costSummary.final.mat > summary.costSummary.budget.mat">
            {{ summary.costSummary.budget.mat - summary.costSummary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td [class.make-red]="summary.costSummary.final.act > summary.costSummary.budget.act">
            {{ summary.costSummary.budget.act - summary.costSummary.final.act | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td [class.make-red]="summary.costSummary.final.act +summary.costSummary.final.mat > summary.costSummary.budget.act + summary.costSummary.budget.mat">
            {{ summary.costSummary.budget.act + summary.costSummary.budget.mat - summary.costSummary.final.act - summary.costSummary.final.mat | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td [class.make-red]="summary.costSummary.final.commissions > summary.costSummary.budget.commissions ">
            {{ summary.costSummary.budget.commissions - summary.costSummary.final.commissions | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td [class.make-red]="summary.costSummary.final.customDuties > summary.costSummary.budget.customDuties ">
            {{ summary.costSummary.budget.customDuties - summary.costSummary.final.customDuties | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td *ngIf="budget.export">{{ summary.costSummary.budget.taxes - summary.costSummary.final.taxes | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}</td>
        <td [class.make-red]="summary.costSummary.finalOffer > summary.costSummary.revenue">
            {{ summary.costSummary.revenue - summary.costSummary.finalOffer | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
        <td [class.make-red]="summary.costSummary.profit.budget > summary.costSummary.profit.final">
            {{ getGenExpProfitDiff(summary) | currency:budget.export ? 'USD' : 'EUR':'symbol':'1.0-0' }}
        </td>
    </tr>
</table>

<ng-template #loadingSummary>
    Loading...
</ng-template>

